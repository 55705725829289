<template>
  <v-container fluid>
    <v-form @submit.prevent="submitSearch" :disabled="isFetching || tableLoading">
      <v-row>
        <v-col cols="12">
          <v-btn
            depressed
            color="primary accent-4"
            class="white--text"
            :to="{ name: 'adminStaffCreate' }"
            :disabled="isFetching || tableLoading"
          >
            新增員工
          </v-btn>
        </v-col>
        <v-col md="6" cols="12">
          <FormControl
            inputType="string"
            :inputValue.sync="searchStaff"
            placeHolder="搜尋員工姓名、電郵"
            :disabled="isFetching || tableLoading"
            :dense="true"
          />
        </v-col>
        <v-col md="6" cols="12" class="d-flex align-center">
          <v-btn
            depressed
            color="success darken-4"
            class="mr-6 white--text"
            type="submit"
            :loading="isFetching || tableLoading"
            small
          >
            搜尋
          </v-btn>
          <v-btn depressed color="error" @click.prevent="clearSearch" :disabled="isFetching || tableLoading" small>
            清除</v-btn
          >

          <!-- <div class="d-flex align-center">
                           <v-dialog  max-width="400" >
                              <template v-slot:activator="{ on, attrs }">
                                 <v-btn depressed 
                                    v-on="on" 
                                    v-bind="attrs" 
                                    small 
                                    color="secondary" 
                                    class="m2 white--text" 
                                    :loading="isFetching" 
                                    :disabled="isFetching">
                                    <v-icon left color="white">{{ icons.mdiDownloadOutline }}</v-icon>導出CSV
                                 </v-btn>
                              </template>
                              <template>
                                 <v-card class="py-3">
                                    <v-card-text>
                                       <div class="text-h6 pa-2">
                                          <FormControl inputType="select" :inputValue.sync="download.date" placeHolder="日期" />
                                       </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-center">
                                       <v-btn depressed 
                                          color="primary accent-4" 
                                          class="primary m-auto white--text" 
                                          block 
                                          download="staff.csv"
                                          :href="exportCsvLink">下載</v-btn>
                                    </v-card-actions>
                                 </v-card>
                              </template>
                           </v-dialog>
                        </div> -->
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mt-6">
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
          :actionViewRouteLink="'adminStaffDetail'"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiDownloadOutline } from '@mdi/js'
import FormControl from '@/components/form/FormControl'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'AdminStaffInfo',
  components: {
    FormControl,
    Datatable,
  },
  data() {
    return {
      isFetching: false,
      tableLoading: false,
      icons: {
        mdiDownloadOutline,
      },
      tableHeaders: [
        { text: '員工姓名', value: 'name' },
        { text: '電郵', value: 'email' },
        { text: '電話', value: 'phone' },
        { text: '職位', value: 'position' },
        { text: '員工類別', value: 'staff_type' },
        { text: '入職日期', value: 'entry_date' },
        { text: '狀態', value: 'staff_status' },
        {
          text: '',
          value: 'actionView',
          sortable: false,
          align: 'end',
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],
      searchStaff: '',
      download: {
        date: '',
      },
    }
  },
  methods: {
    async getStaffData() {
      if (!this.tableLoading && !this.isFetching) {
        this.tableLoading = true

        try {
          const payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
            verify_token: this.getAdminToken(),
          }

          if (this.$validate.DataValid(this.searchStaff)) {
            payload.search_keyword = this.searchStaff
          }

          const result = await this.$XHR.api('cms_get_staff', payload)
          this.$func.log('-----Get Staff List-----')
          this.$func.log(result)

          this.formItemTotal = result.total

          const temp = []

          for (let i = 0; i < result.data.length; i++) {
            const resultData = result.data[i]

            temp.push({
              id: resultData.id,
              name: resultData.name,
              email: resultData.email,
              phone: resultData.phone,
              position: resultData.position,
              staff_type: resultData.staff_type,
              entry_date: resultData.entry_date,
              staff_status: resultData.staff_status,
            })
          }

          this.formData = temp
        } catch (error) {
          this.$func.log('-----Get Staff fail-----')
          this.$func.log(error)

          let msg = ''

          if (error.data === 'no permission') {
            msg = '沒有權限'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }

          this.formData = []
          this.formItemTotal = 0
        } finally {
          this.tableLoading = false
        }
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getStaffData()
    },
    submitSearch: function () {
      if (this.$validate.DataValid(this.searchStaff)) {
        this.formItemTotal = 0
        this.formData = []
        this.tablePage = 1

        this.getStaffData()
      }
    },
    clearSearch() {
      this.searchStaff = ''
      this.formItemTotal = 0
      this.tablePage = 1
      this.formData = []
      this.getStaffData()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getStaffData()
    }
  },
}
</script>